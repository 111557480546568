<template>
  <div>
    <div class="content-header">
      <h2>{{ $tfo('batch_upload_logs') }}</h2>
    </div>

    <el-row class="cols--center mar-v--1" row-key="id">
      <el-button name="back" @click="$router.back()">{{ $tfo('back') }}</el-button>
      <el-button name="delete-btn" @click="deleteSelection" :disabled="!(selection && selection.length)"
        >{{ $tf(['common.delete']) }} {{ selection && selection.length ? selection.length : '' }}
      </el-button>
      <div class="cell"></div>
      <div name="table-pagination" class="table-pagination pull-right mar-left--1">
        <table-navigation :state="state"></table-navigation>
      </div>
    </el-row>

    <el-table
      name="batch-upload-table"
      stripe
      :size="$vars.sizes.table || $vars.sizes.common"
      :data="state.items"
      v-loading="state.loading"
      @selection-change="selectionChange"
      @row-click="rowClick"
      class="batch-upload-table"
    >
      <el-table-column type="selection"></el-table-column>
      <el-table-column prop="id" :label="$tf('common.id')" width="60"></el-table-column>
      <el-table-column prop="name" :label="$tf('common.name')"></el-table-column>
      <el-table-column prop="created_date" width="120px" :label="$tf('common.created')">
        <template slot-scope="{ row }">
          {{ row.created_date | isoStringToDate | formatDate }}
          <br />
          {{ row.created_date | isoStringToDate | formatTime }}
        </template>
      </el-table-column>
      <el-table-column prop="success_count" :label="$tf('success_count')" width="175"></el-table-column>
      <el-table-column prop="fail_count" :label="$tf('failed_count')" width="200"></el-table-column>
      <el-table-column :label="$tf('download | csv')" width="120">
        <template slot-scope="{ row }">
          <el-button @click.stop="(e) => downloadCsv(e, row)" :disabled="!(row.success_count || row.fail_count)">
            {{ $tfo('download') }}
          </el-button>
        </template>
      </el-table-column>
    </el-table>

    <el-row class="cols--center">
      <div class="cell"></div>
      <div name="table-pagination" class="table-pagination pull-right mar-v--1">
        <table-navigation :state="state"></table-navigation>
      </div>
    </el-row>
  </div>
</template>

<script>
import TableNavigation from '@/components/tables/navigation.vue';
import qs from 'qs';
import _ from '@/apps/common/lodash';
import axios from 'axios';

function alphabeticalSort(a, b) {
  return a.localeCompare(b);
}

export default {
  components: {
    TableNavigation
  },
  name: 'page-batch-upload',
  props: {
    cardType: {
      type: String,
      default: 'human'
    }
  },
  data: () => ({ selection: null, hasFilters: false }),
  computed: {
    state() {
      return this.$store.state.batch_upload;
    }
  },
  watch: {
    '$route.params.filter': function (v, p) {
      if (this.applyQueryFilter(v)) {
      }

      if (!this.state.filter.current.page && this.state.prev_page.length) {
        this.state.prev_page = [];
      }

      this.loadItems();
    },
    '$store.state.batch_upload.filter.current': {
      deep: true,
      handler: function (v, p) {
        let filter = _.pickBy(v, (v) => !!v),
          filterString = qs.stringify(filter, { sort: alphabeticalSort });

        if (this.$route.params.filter !== filterString) {
          this.$router.push({ path: '/batch-upload/filter/' + filterString });
        }
      }
    }
  },
  mounted() {
    this.loadItems();
  },
  methods: {
    rowClick(item) {
      this.$router.push({ path: this.getItemLink(item), query: { cardType: this.cardType } });
    },
    selectionChange(v) {
      this.selection = v;
    },
    deleteSelection(v) {
      Promise.all(
        this.selection.map((v) => {
          return this.$store.dispatch(this.state.Action.Delete, { id: v.id });
        })
      )
        .then((v) => {
          this.$notify({ type: 'success', message: this.$tf(['common.action', 'common.success']) });
          this.$store.dispatch(this.state.Action.Get);
        })
        .catch((e) => {
          this.$notify({ duration: 0, message: this.$createElement('message-box', { props: { e: e } }) });
          this.$store.dispatch(this.state.Action.Get);
        });
    },
    getItemLink(item) {
      return '/batch-upload-entry/filter/limit=100&resp_code_gt=399&upload_list=' + encodeURIComponent(item.id);
    },
    applyQueryFilter(v) {
      let parsed = qs.parse(v, { arrayLimit: 100 }),
        objectFilter = Object.assign(_.cloneDeep(this.state.filter.empty), parsed),
        filtersEqual = false;

      objectFilter['limit'] = parseInt(objectFilter['limit']);
      filtersEqual = _.isEqual(this.state.filter.current, objectFilter);

      if (!filtersEqual) {
        this.state.filter.current = objectFilter;
      }

      this.hasFilters = !_.isEqual(objectFilter, this.state.filter.empty);
      return !filtersEqual;
    },
    loadItems() {
      this.$store.dispatch(this.state.Action.Get);
    },
    downloadCsv(e, item) {
      e.stopPropagation();
      const url = this.$store.state.config.server.url + 'batch-upload/' + item.id + '/csv/?secret_key=' + encodeURIComponent(item.secret_key);
      axios({
        url,
        responseType: 'blob',
        headers: {
          Authorization: 'Token ' + encodeURIComponent(this.$store.state.app.token)
        }
      }).then((v) => {
        const url = window.URL.createObjectURL(new Blob([v.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', item.name + '.csv');
        document.body.appendChild(link);
        link.click();
      });
    }
  }
};
</script>
<style>
.batch-upload-table .el-table__row {
  cursor: pointer;
}
</style>
